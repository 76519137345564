(function ($) {

  var $window = $(window),
    $body = $('body'),
    settings = {

      // Carousels
      carousels: {
        speed: 6,
        fadeIn: true,
        fadeDelay: 250
      },

    };

  const cards = document.querySelectorAll(".card");

  for (let i = 0; i < cards.length; i++) {
    cards[i].addEventListener("click", function() {
      this.classList.toggle("is-flipped");
    });
  }

  // Play initial animations on page load.
  $window.on('load', function () {
    window.setTimeout(function () {
      $body.removeClass('is-preload');
    }, 100);
  });

  // Scrolly.
  $('.scrolly').scrolly();

  // Carousels.
  $('.carousel').each(function () {

    var $t = $(this),
      $forward = $('<span class="forward"></span>'),
      $backward = $('<span class="backward"></span>'),
      $reel = $t.children('.reel'),
      $items = $reel.children('article');

    var pos = 0,
      leftLimit,
      rightLimit,
      itemWidth,
      reelWidth,
      timerId;

    // Items.
    if (settings.carousels.fadeIn) {

      $items.addClass('loading');

      $t.scrollex({
        mode: 'middle',
        top: '-20vh',
        bottom: '-20vh',
        enter: function () {

          var timerId,
            limit = $items.length - Math.ceil($window.width() / itemWidth);

          timerId = window.setInterval(function () {
            var x = $items.filter('.loading'), xf = x.first();

            if (x.length <= limit) {

              window.clearInterval(timerId);
              $items.removeClass('loading');
              return;

            }

            xf.removeClass('loading');

          }, settings.carousels.fadeDelay);

        }
      });

    }

    // Main.
    $t._update = function () {
      pos = 0;
      rightLimit = (-1 * reelWidth) + $window.width();
      leftLimit = 0;
      $t._updatePos();
    };

    $t._updatePos = function () {
      $reel.css('transform', 'translate(' + pos + 'px, 0)');
    };

    // Forward.
    $forward
      .appendTo($t)
      .hide()
      .mouseenter(function (e) {
        timerId = window.setInterval(function () {
          pos -= settings.carousels.speed;

          if (pos <= rightLimit) {
            window.clearInterval(timerId);
            pos = rightLimit;
          }

          $t._updatePos();
        }, 10);
      })
      .mouseleave(function (e) {
        window.clearInterval(timerId);
      });

    // Backward.
    $backward
      .appendTo($t)
      .hide()
      .mouseenter(function (e) {
        timerId = window.setInterval(function () {
          pos += settings.carousels.speed;

          if (pos >= leftLimit) {

            window.clearInterval(timerId);
            pos = leftLimit;

          }

          $t._updatePos();
        }, 10);
      })
      .mouseleave(function (e) {
        window.clearInterval(timerId);
      });

    // Init.
    $window.on('load', function () {

      reelWidth = $reel[0].scrollWidth;

      if (/Mobi|Android/i.test(navigator.userAgent)) {

        $reel
          .css('overflow-y', 'hidden')
          .css('overflow-x', 'scroll')
          .scrollLeft(0);
        $forward.hide();
        $backward.hide();

      } else {

        $reel
          .css('overflow', 'visible')
          .scrollLeft(0);
        $forward.show();
        $backward.show();
      }

      $t._update();

      $window.on('resize', function () {
        reelWidth = $reel[0].scrollWidth;
        $t._update();
      }).trigger('resize');

    });

  });

})(jQuery);
